<template>
  <div class="main">
    <a-row align="middle" justify="center" type="flex">
      <a-col :span="24">
        <a-page-header title="unionID屏蔽名单" sub-title="名单中的用户将跳转读书页面" />
      </a-col>
    </a-row>
    <a-row>
      <!-- 菜单列表 -->
      <a-spin tip="Loading..." :spinning="listLoading">
        <a-col :span="24" style="display: inline-flex;flex-wrap: wrap;">
          <div v-for="(i,key) in data" :key="key" class="mini" @click="cancle(i.id)">
            <span>{{i.unionid}}</span><a-icon type="delete" class="icon"></a-icon>
          </div>
        </a-col>
      </a-spin>
    </a-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: [],
      listLoading: false, //列表loadding
    };
  },
  mounted() {
    this.getListData();
  },
  methods: {
    getListData() {
      this.listLoading = true;
      this.$http
        .get("/admin/user/blackList")
        .then(res => {
          //请求成功后的处理函数
          this.listLoading = false;
          this.data = res.data.result;
        })
        .catch(() => {
          //请求失败后的处理函数
          this.listLoading = false;
        });
    },
    cancle(id){
      this.$http
        .get("/admin/user/cancleBlack", { params: { id: id }})
        .then(() => {
          this.getListData();
        })
    }
  }
};
</script>
<style lang="scss" scoped>
  .mini,.add{
    height: 34px;
    background-color: #f3f6fb;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 12px;
    margin-bottom: 10px;
    color: #444;
    font-size: 12px;
    padding: 0 12px;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 2px;
    > .icon{
      margin-left: 4px;
      opacity: 0;
    }
    &:hover{
      background-color: #ebf4ff;
      > .icon{
        opacity: 1;
      }
    }
  }
</style>